<template>
  <Auth />
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12"></div>
          <div class="col-lg-3 col-md-6 col-12"></div>
          <div class="col-lg-3 col-md-6 col-12">
            <CardTotalFirstAttemptDeliveryFail :is_admin="true" />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <CardSuccessPercentage :is_admin="true" />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">Alert Kiriman</h5>
            <p class="mb-0 text-sm">
              Daftar paket dalam kondisi <i>Alert Attempt Delivery Fail</i>.
            </p>
          </div>
          <div class="card-body" style="border-top: 1px solid #eeeeee">
            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-6 text-end">
                <div class="btn-group" role="group">
                  <button
                    type="button"
                    class="btn btn-sm btn-warning"
                    @click.prevent="checkingLastUpdate"
                  >
                    Checking Last Update
                  </button>
                  <div class="dropdown" style="margin-left: 0px">
                    <button
                      id="dropdownMenuButton1"
                      class="btn btn-sm btn-dark dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style="border-radius: 0px 5px 5px 0px"
                    >
                      More
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a class="dropdown-item" @click="refreshTable"
                          >Refresh Tabel</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                &nbsp;
              </div>
            </div>
            <div>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <el-select
                  v-model="pagination.perPage"
                  class="select-primary pagination-select"
                  placeholder="Per page"
                >
                  <el-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    class="select-primary"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>

                <div>
                  <el-input
                    v-model="searchQuery"
                    type="search"
                    class="mb-3"
                    clearable
                    prefix-icon="el-icon-search"
                    style="width: 200px"
                    placeholder="Search records"
                    aria-controls="datatables"
                  >
                  </el-input>
                </div>
              </div>
              <div class="table-responsive">
                <el-table
                  :data="queriedData"
                  row-key="id"
                  header-row-class-name="thead-light"
                  @selection-change="selectionChange"
                >
                  <el-table-column
                    v-for="column in tableColumns"
                    :key="column.label"
                    v-bind="column"
                  >
                  </el-table-column>
                  <el-table-column
                    min-width="50px"
                    align="left"
                    label="AWB No."
                  >
                    <template #default="props">
                      <small
                        ><b>{{ props.row.merchant_order_number }}</b
                        ><br /><span v-if="props.row.email != ''">{{
                          props.row.email
                        }}</span></small
                      >
                    </template>
                  </el-table-column>
                  <el-table-column align="left" label="Status">
                    <template #default="props">
                      <b class="text-danger">{{ props.row.status }}</b>
                      <br />
                      <small
                        ><i
                          ><span style="color: #aaaaaa">{{
                            props.row.display_created_at
                          }}</span
                          ><br />"{{ props.row.comments }}"</i
                        >
                      </small>
                    </template>
                  </el-table-column>
                  <el-table-column align="left" label="Last Status">
                    <template #default="props">
                      <span v-if="props.row.status != props.row.last_status">
                        <TrackParcelStatus
                          :status="props.row.last_status"
                        /> </span
                      ><br />
                      <small style="color: #aaaaaa"
                        ><i>{{ props.row.display_updated_at }}</i></small
                      ><br />
                      <span v-if="props.row.status != props.row.last_status">
                        <small
                          ><i>"{{ props.row.last_comments }}"</i></small
                        >
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column align="left" label="Completed">
                    <template #default="props">
                      <span v-if="props.row.completed == 1">
                        <argon-badge variant="gradient" color="dark"
                          >completed</argon-badge
                        >
                      </span>
                      <span v-else>-</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <br />
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                  </p>
                </div>
                <base-pagination
                  v-model="pagination.currentPage"
                  class="pagination-no-border"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <modal v-model:show="modals.classic" size="lg">
    <div class="p-2">
      <h5>Modal action</h5>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import Auth from "@/components/auth/Auth";
import Modal from "@/components/Modal";
//import Swal from "sweetalert2";
import ArgonBadge from "@/components/ArgonBadge.vue";
import TrackParcelStatus from "@/components/TrackParcelStatus.vue";
import CardTotalFirstAttemptDeliveryFail from "@/components/Overview/CardTotalFirstAttemptDeliveryFail.vue";
import CardSuccessPercentage from "@/components/Overview/CardSuccessPercentage.vue";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
  ElLoading,
} from "element-plus";
import BasePagination from "@/components/BasePagination";

export default {
  name: "AttemptDeliveryFailFromAdmin",
  components: {
    BasePagination,
    Auth,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    Modal,
    ArgonBadge,
    TrackParcelStatus,
    CardSuccessPercentage,
    CardTotalFirstAttemptDeliveryFail,
  },
  data() {
    return {
      modals: {
        classic: false,
      },
      urlread:
        "/api/v1/admin/alert_send_package_status/attempt_delivery_fail/read",
      urlchecking_last_update:
        "/api/v1/admin/alert_send_package_status/attempt_delivery_fail/checking_last_update",

      dataTable: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["merchant_order_number", "email", "display_completed"],
      tableColumns: [
        {
          type: "selection",
        },
      ],
      datas: [],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      isLoading: false,
      loadingTable: true,
      basePath: axios.defaults.baseURL,

      selects: {
        actives: [
          {
            value: 1,
            name: "Active",
          },
          {
            value: 0,
            name: "Not Active",
          },
        ],
        isdefaults: [
          {
            value: 1,
            name: "Yes",
          },
          {
            value: 0,
            name: "No",
          },
        ],
      },

      form: {
        id: "",
        code: "",
        name: "",
        cod: "",
        cod_percentage: "",
        insurance: "",
        insurance_percentage: "",
        isdefault: "",
        active: "",
      },
      showOpsiCOD: false,
      showOpsiAsuransi: false,
      checkedOpsiCOD: false,
      checkedOpsiAsuransi: false,
      urlReadLogo: "",
      urlUploadLogo: "",
      showUploadLogo: false,
    };
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      return this.searchAction.slice(this.from, this.to);
    },
    searchAction() {
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          if (row[key] !== null) {
            let rowValue = row[key].toString().toLowerCase();
            if (
              rowValue.includes &&
              rowValue.includes(this.searchQuery.toLowerCase())
            ) {
              isIncluded = true;
            }
          }
        }
        return isIncluded;
      });

      return result;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchAction.length > 0
        ? this.searchAction.length
        : this.tableData.length;
    },
  },
  created() {
    this.getTables();
  },
  methods: {
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });

      return loading;
    },

    async getTables() {
      try {
        this.loadingService();
        const response = await axios.get(this.urlread, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        console.log(response);
        this.datas = response.data.data;
        this.tableData = this.datas;
        //console.log(this.datas);
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },

    refreshTable() {
      this.getTables();
    },
    clearForm() {
      for (let x in this.form) {
        this.form[x] = "";
      }
    },
    async checkingLastUpdate() {
      try {
        this.loadingService();
        const response = await axios.get(this.urlchecking_last_update, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        console.log(response);
        this.getTables();
        //this.datas = response.data.data;
        //this.tableData = this.datas;
        //console.log(this.datas);
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    async showEditForm(index, row) {
      try {
        console.log(index + " " + row);
        this.modals.classic = true;
        this.clearForm();
        this.loadingService();

        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },

    errorRedirect500(error) {
      if (error.response.status == 500) {
        setTimeout(() => {
          this.$router.push({ path: "/authentication/error/error500" });
        }, 2000);
      }
    },
  },
};
</script>
