<template>
  <mini-statistics-card
    title="Success(%)"
    :value="displayPercentage"
    description="<span
                    class='text-sm font-weight-bolder text-danger'
                    >+0%</span> bulan lalu"
    :icon="{
      component: 'ni ni-favourite-28',
      background: 'bg-gradient-success',
      shape: 'rounded-circle',
    }"
  />
</template>

<script>
import axios from "axios";
import MiniStatisticsCard from "../../examples/Cards/MiniStatisticsCard.vue";
export default {
  name: "CardSuccessPercentage",
  components: {
    MiniStatisticsCard,
  },
  props: {
    is_admin: { required: false, type: Boolean, default: false },
  },
  data() {
    return {
      urlread: "/api/v1/alert_send_package_status/successful_delivery/percentage",
      urlreadfromadmin:
        "/api/v1/admin/alert_send_package_status/successful_delivery/percentage",
      grandTotal: 0,
      displayPercentage: "loading...",
    };
  },
  created() {
    this.GetTotal();
  },
  methods: {
    async GetTotal() {
      try {
        let url = this.is_admin == true ? this.urlreadfromadmin : this.urlread;
        const response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        //this.datas = response.data.data.data;
        this.percentage = response.data.data.percentage;
        this.displayPercentage = response.data.data.percentage;

        console.log(response.data);
      } catch (error) {
        console.log(error);
        this.displayGrandTotal = "error";
      }
    },
  },
};
</script>
