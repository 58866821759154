<template>
  <mini-statistics-card
    title="Attempt Delivery Fail"
    :value="displayGrandTotal"
    description="<span
                  class='text-sm font-weight-bolder text-danger'
                  >+0%</span> bulan lalu"
    :icon="{
      component: 'ni ni-ambulance',
      background: 'bg-gradient-danger',
      shape: 'rounded-circle',
    }"
  />
</template>

<script>
import axios from "axios";
import MiniStatisticsCard from "../../examples/Cards/MiniStatisticsCard.vue";
export default {
  name: "CardTotalFirstAttemptDeliveryFail",
  components: {
    MiniStatisticsCard,
  },
  props: {
    is_admin: { required: false, type: Boolean, default: false },
  },
  data() {
    return {
      urlread: "/api/v1/alert_send_package_status/attempt_delivery_fail/total",
      urlreadfromadmin: "/api/v1/admin/alert_send_package_status/attempt_delivery_fail/total",
      grandTotal: 0,
      displayGrandTotal: "loading...",
    };
  },
  created() {
    this.GetTotal();
  },
  methods: {
    async GetTotal() {
      try {
        let url = this.is_admin == true ? this.urlreadfromadmin : this.urlread;
        const response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        //this.datas = response.data.data.data;
        this.grandTotal = response.data.data.total;
        this.displayGrandTotal = response.data.data.display_total;

        console.log(response.data);
      } catch (error) {
        console.log(error);
        this.displayGrandTotal = "error";
      }
    },
  },
};
</script>
